import React, { useEffect, useState } from 'react';
import store, { actions } from 'redux/store';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import ModalAddress from 'components/GuestMode/ModalAddress';
import DrawerMember from 'components/UI/Drawer/DrawerMember';
import Auth from 'components/Auth';
import Deeplink from 'components/UI/Deeplink';
import { isAuthenticated, clearAuth } from 'utils/_auth.js';
import { md } from 'utils/breakpoints';
import { clearDispatchData } from 'utils/_dispatch.js';
import IconHamburger from 'images/hamburger-1.svg';
import IconLogo from 'images/logo.png';
import IconAddressMap from 'images/guest-mode-address-map.svg';
import BgGuestMode from 'images/guest-mode-bg.jpg';
import BgGuestModeMobile from 'images/guest-mode-bg-m.jpg';
import { PATH_HOME } from 'utils/constants/paths.js';
import TitleName from 'components/UI/Header/TitleName';
import Maintenance from 'components/UI/Maintenance';

const LAYOUT_WIDTH_DESKTOP = '95vw';
const LAYOUT_WIDTH_MOBILE = '90vw';

const Container = styled.div`
  height: 100vh;
  background-image: url(${BgGuestMode});
  background-size: cover;
  background-position: bottom right;
  position: relative;

  @media (max-width: ${md}) {
    background-image: url(${BgGuestModeMobile});
  }
`;

const TitleBar = styled.div`
  width: ${LAYOUT_WIDTH_DESKTOP};
  display: flex;
  align-items: center;
  margin: 0 auto 110px;
  padding: 25px 0 10px;

  @media (max-width: ${md}) {
    width: ${LAYOUT_WIDTH_MOBILE};
    margin: 0 auto 40px;
  }
`;

const BtnHamburger = styled.img`
  cursor: pointer;

  @media (max-width: ${md}) {
    display: inline-block;
    width: 30px;
  }
`;

const StyledIconLogo = styled.img`
  width: 160px;
  margin-left: 40px;

  @media (max-width: ${md}) {
    width: 111px;
    margin-left: 15px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0 8px;
  margin-left: auto;
`;

const Btn = styled.div`
  width: 114px;
  height: 38px;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${md}) {
    width: 80px;
    height: 35px;
  }
`;

const BtnSignUp = styled(Btn)`
  border: 1px solid #5fd2da;
  background-color: #eafeff;
`;

const BtnSignIn = styled(Btn)`
  background-color: #ffffff;
  border: 1px solid #ffffff;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  width: ${LAYOUT_WIDTH_DESKTOP};
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  @media (max-width: ${md}) {
    position: static;
    width: ${LAYOUT_WIDTH_MOBILE};
    transform: none;
  }
`;

const ContentTitleWrapper = styled.div`
  color: #3b3516;
  margin-bottom: 36px;

  @media (max-width: ${md}) {
    margin-bottom: 16px;
  }
`;

const ContentMainTitle = styled.div`
  font-size: 48px;
  font-weight: 700;

  @media (max-width: ${md}) {
    font-size: 30px;
  }
`;

const ContentSubTitle = styled.div`
  font-size: 30px;
  font-weight: 700;

  @media (max-width: ${md}) {
    font-size: 20px;
  }
`;

const Content = styled.div``;

const ContentRow = styled.div`
  &:nth-child(1) {
    display: flex;
    gap: 0 11px;
    height: 52px;
    margin-bottom: 16px;

    @media (max-width: ${md}) {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 16px 0;
    }
  }
`;

const InputAddressWrapper = styled.div`
  position: relative;

  > img {
    position: absolute;
    width: 21px;
    height: 24px;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
`;

const InputAddress = styled.input`
  appearance: none;
  outline: none;
  border: none;
  width: 535px;
  height: 100%;
  padding: 14px 14px 14px 37px;
  border: 1px solid #5fd2da;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  cursor: pointer;

  @media (max-width: ${md}) {
    width: 100%;
    height: 52px;
  }
`;

const BtnDelivery = styled.div`
  width: 113px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 4px;
  cursor: pointer;

  @media (max-width: ${md}) {
    width: 100%;
    height: 52px;
    justify-content: flex-start;
    padding: 14px 12px;
  }
`;

const BtnSearch = styled.div`
  width: 136px;
  height: 100%;
  background-color: #5fd2da;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;

  @media (max-width: ${md}) {
    width: 100%;
    height: 52px;
    border-radius: 30px;
  }
`;

const RemindText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;

  > span {
    color: #000000;
    cursor: pointer;
  }
`;

const NoticeWrapper = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 auto 10px;

  @media (max-width: ${md}) {
    width: 341px;
    font-size: 12px;
    margin: 0 auto 20px;
  }

  > ul {
    width: 435px;

    @media (max-width: ${md}) {
      width: 270px;
    }
  }
`;

const GuestMode = () => {
  const [modalAddress, setModalAddress] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [doLogin, setDoLogin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const openModalAddress = () => {
    setModalAddress(true);
  };

  const closeModalAddress = () => {
    setModalAddress(false);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const handleClickSignUp = () => {
    setIsLogin(false);
    setDoLogin(true);
  };

  const handleClickLogin = () => {
    setIsLogin(true);
    setDoLogin(true);
  };

  useEffect(() => {
    //執行登出囉
    if (localStorage.getItem('doLogout') !== null) {
      sessionStorage.removeItem('isSSO');
      localStorage.removeItem('doLogout');
      clearAuth();
      store.dispatch(actions.setUserInfo({}));
      store.dispatch(actions.setAuthIsReady(false));
      store.dispatch(actions.setInfo({}));
      store.dispatch(actions.setCartItemCount(0));
      store.dispatch(actions.setCartProductCountList([]));
      clearDispatchData();
    }
    if (isAuthenticated()) {
      navigate(PATH_HOME);
    }
  }, []);

  // 如果是上一頁回來訪客頁後，需要把狀態重置，否則進不了 <Auth />
  useEffect(() => {
    if (!doLogin) return;
    setDoLogin(false);
  }, [doLogin]);

  return (
    <>
      <TitleName />
      <Container>
        <TitleBar>
          <BtnHamburger
            src={IconHamburger}
            onClick={() => setOpenDrawer(true)}
          />
          <StyledIconLogo src={IconLogo} />
          <ButtonGroup>
            <BtnSignUp onClick={handleClickSignUp}>註冊</BtnSignUp>
            <BtnSignIn onClick={handleClickLogin}>登入</BtnSignIn>
          </ButtonGroup>
        </TitleBar>
        <ContentWrapper>
          <ContentTitleWrapper>
            <ContentMainTitle>家速配 最快1小時到貨！</ContentMainTitle>
            <ContentSubTitle>#家樂福快速配送服務</ContentSubTitle>
          </ContentTitleWrapper>
          <Content>
            <ContentRow>
              <InputAddressWrapper onClick={openModalAddress}>
                <img src={IconAddressMap} />
                <InputAddress
                  placeholder="輸入外送地址，即刻瀏覽商品"
                  readOnly
                />
              </InputAddressWrapper>
              {/* <BtnDelivery onClick={openModalAddress}>
                <img src={IconClock} />
                立即外送
              </BtnDelivery> */}
              {/* <BtnSearch onClick={openModalAddress}>尋找商品</BtnSearch> */}
            </ContentRow>
            <ContentRow>
              <RemindText>
                <span>登入</span>即可選擇您最近使用的地址
              </RemindText>
            </ContentRow>
          </Content>
        </ContentWrapper>
      </Container>
      <ModalAddress open={modalAddress} onClose={closeModalAddress} />
      {openDrawer && <DrawerMember open={openDrawer} onClose={closeDrawer} />}
      {doLogin && <Auth url={PATH_HOME} isLogin={isLogin}></Auth>}
      <Maintenance />
      <Deeplink />
    </>
  );
};

export default GuestMode;
